<template>
 <!-- 客房事件页面 -->
 <!-- 头部选择器 -->
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="80px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="3">
      <el-form-item label="品牌">
       <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="门店">
       <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="卡号">
       <el-input v-model="searchForm.cardSn" clearable></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="卡类型">
       <el-select v-model="searchForm.cardType" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.cardTypes" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="使用者">
       <el-input v-model="searchForm.employeeName" clearable></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="7">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>
      </div>
     </el-col>
    </el-row>
   </el-form>
  </el-card>
  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.type=='operation'">
       <el-button type="danger" v-if="showDelete" size="mini" @click="deleteById(scope.row)">删除</el-button>
      </div>
      <div v-else-if="item.solt_TEXT">
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
      </div>
      <div v-else-if="item.value=='opsAverageTime'">
       {{showLongTimes(scope.row.opsAverageTime)}}
      </div>
      <div v-else-if="item.value=='opsPercent'">
       {{scope.row[item.value]}}%
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>
 </div>
</template>
<script>
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";
import { cardList, deleteCard, getBrandSelect, getHotelSelect } from "@/api";
const typeLists = {};
export default {
 data() {
  return {
   height: null,
   tablist: [],
   execlTab: [
    {
     label: "品牌",
     value: "brandName",
    },
    {
     label: "门店",
     value: "hotelName",
     width: 280,
    },
    {
     label: "卡号",
     value: "cardSn",
    },
    {
     label: "卡类型",
     value: "cardType",
     solt_TEXT: "cardTypes",
    },
    {
     label: "卡状态",
     value: "cardStatus",
     solt_TEXT: "cardStatus",
    },
    {
     label: "使用者",
     value: "employeeName",
    },
    {
     label: "操作",
     type: "operation",
     width: 250,
    },
   ],
   loading: false,
   downLoading: false,
   // 查询的数据
   searchForm: {
    brandId: null,
    hotelId: null,
    cardSn: null,
    cardType: null,
    employeeName: null,
   },
   total: 0,
   pageNum: 1,
   pageSize: 10,
   listQuery: {},
   brandSelectData: null,
   hotelSelectData: null,
   startDateDisabled: {
    // 限制日期
    disabledDate: this.startDisabled,
   },
   endDateDisabled: {
    // 限制日期
    disabledDate: this.endDisabled,
   },
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 235;
 },
 computed: {
  typeLists() {
   let obj = typeLists;
   obj.cardTypes = this.$store.state.cardTypes;
   obj.cardStatus = this.$store.state.cardStatus;
   return obj;
  },
  showDelete: function () {
   return this.$store.state.roleMenu.includes("cardManage:delete");
  },
 },

 created() {
  this.getList();
  this.initialize();
 },
 methods: {
  openpopup() {
   this.$refs.add.openSet();
  },
  edit(row) {
   this.$refs.add.openSet(row);
  },
  deleteById(row) {
   //
   this.$confirm("此操作将永久删除该取电卡, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     this.loading = true;
     deleteCard(row.cardId)
      .then((res) => {
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success("删除成功");
        this.getList();
       } else {
        this.$message.warning(res.data.message);
       }
      })
      .catch((err) => {
       this.loading = false;
       this.$message.warning("删除失败");
      });
    })
    .catch(() => {});
  },
  setBan(row) {},
  UploadImage(e) {
   console.log(e, "UploadImage");
   this.downLoading = true;
   const formData = new FormData();
   formData.append("file", e.file); // 要提交给后台的文件
   formData.append("name", "批量取电卡"); // 这个接口必要的项目id
  },
  beforeUpload(e) {
   var testmsg = e.name.substring(e.name.lastIndexOf(".") + 1);
   const extension = testmsg === "xls";
   const extension2 = testmsg === "xlsx";
   if (extension || extension2) return true;
   else this.$message.warning("只能上传xls/xlsx文件");
   return false;
  },

  startDisabled(t) {
   if (this.searchForm.endDate && t)
    return new Date(t).getTime() > new Date(this.searchForm.endDate).getTime();
   return false;
  },
  endDisabled(t) {
   if (this.searchForm.startDate && t)
    return (
     new Date(t).getTime() < new Date(this.searchForm.startDate).getTime()
    );
   return false;
  },
  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery = {};
   for (let key in this.searchForm) {
    if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.searchForm.roomNumber = null;
   this.hotelSelectData = null;
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   this.searchForm = {
    brandId: null,
    hotelId: null,
    cardSn: null,
    cardType: null,
    employeeName: null,
   };
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   cardList(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped >
.upload-demo {
 display: inline-block;
 margin-left: 10px;
}

.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }
 
}
</style>


